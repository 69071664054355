var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 bg-white",attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})],1)],1),_vm._v(" "+_vm._s(_vm.$t("DetallesUsuario"))+" ")])]),_c('validation-observer',{ref:"editUser"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 auth-create-form",attrs:{"cols":"6"}},[(_vm.userPrepare)?_c('ImageDropzone',{ref:"images",attrs:{"files":_vm.files}}):_vm._e()],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"account-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Apellidos'),"label-for":"account-surname"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Apellidos'),"name":"surname"},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}})],1),_c('validation-provider',{attrs:{"name":_vm.$t('Telefono'),"rules":"max:9|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Telefono'),"label-for":"account-phone"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('vue-country-code',{key:_vm.codePhone,attrs:{"default-country":_vm.codePhone,"disabled-fetching-country":true},on:{"onSelect":_vm.onSelect}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","name":"company","placeholder":_vm.$t('Telefono')},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-checkbox',{staticClass:"mb-1 mt-1",attrs:{"id":"emailTrue","name":"check-button","inline":""},model:{value:(_vm.not_email),callback:function ($$v) {_vm.not_email=$$v},expression:"not_email"}},[_c('span',[_vm._v(_vm._s(_vm.$t("NoEmail")))])])],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":_vm.requiredEmail},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"account-e-mail"}},[_c('b-form-input',{attrs:{"disabled":_vm.disableEmail,"name":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Username'),"rules":_vm.requiredUsername},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Username'),"label-for":"account-username"}},[_c('b-form-input',{attrs:{"name":"username","disabled":_vm.disableUsername,"placeholder":_vm.$t('Username')},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Dni"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Dni'),"label-for":"account-dni"}},[_c('b-form-input',{attrs:{"name":"dni","placeholder":_vm.$t('Dni')},model:{value:(_vm.user.dni),callback:function ($$v) {_vm.$set(_vm.user, "dni", $$v)},expression:"user.dni"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Rol'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Rol'),"label-for":"account-rols"}},[_c('v-select',{attrs:{"label":"display_name","filterable":false,"searchable":false,"options":_vm.selectRoles},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(
            _vm.currentRole == 'admin_empresa' ||
            _vm.currentRole == 'admin_cliente' ||
            _vm.currentRole == 'super_admin'
          )?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Activado'),"label-for":"account-active"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"switch":"","value":"1"},model:{value:(_vm.user.active),callback:function ($$v) {_vm.$set(_vm.user, "active", $$v)},expression:"user.active"}})],1)],1):_vm._e(),(
            _vm.currentRole == 'admin_empresa' || _vm.currentRole == 'super_admin'
          )?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Compañía'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Compañía'),"label-for":"account-company"}},[_c('v-select',{attrs:{"label":"name","filterable":false,"searchable":false,"options":_vm.selectCompanies,"placeholder":_vm.$t('Compañía')},model:{value:(_vm.companies),callback:function ($$v) {_vm.companies=$$v},expression:"companies"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1891827986)})],1):_vm._e(),(
            _vm.currentRole == 'admin_empresa' || _vm.currentRole == 'super_admin'
          )?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Cliente'),"rules":_vm.role &&
              (_vm.role.name === 'admin_cliente' ||
                _vm.role.name === 'operario_cliente' ||
                _vm.role.name === 'prl_cliente')
                ? 'required'
                : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Cliente'),"label-for":"account-clients"}},[_c('v-select',{attrs:{"label":"name","filterable":false,"searchable":false,"options":_vm.selectClients,"multiple":"","disabled":_vm.disabledClients,"placeholder":_vm.$t('Cliente')},model:{value:(_vm.clients),callback:function ($$v) {_vm.clients=$$v},expression:"clients"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,460737925)})],1):_vm._e(),(_vm.clients.length > 0)?_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Departamentos","label-for":"account-departments"}},_vm._l((_vm.departmentsSelect),function(item){return _c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v(_vm._s(item.name))])]),_c('b-row',_vm._l((item.data),function(dep){return _c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-checkbox',{attrs:{"value":dep.id,"plain":""},model:{value:(_vm.departments),callback:function ($$v) {_vm.departments=$$v},expression:"departments"}},[_vm._v(" "+_vm._s(dep.name)+" ")])],1)}),1)],1)}),0)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"value":"true","plain":""},model:{value:(_vm.changePassword),callback:function ($$v) {_vm.changePassword=$$v},expression:"changePassword"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("CambiarContrasenya")))])])],1),(_vm.changePassword)?_c('b-form-group',{attrs:{"label-for":"account-password"}},[_c('b-form-input',{attrs:{"name":"password","placeholder":_vm.$t('Contrasenya')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Guardar"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }